import React, {useContext} from 'react';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../components/css/index.css';
import CurrentOpenPositions from "../components/CurrentOpenPositions";
import {GlobalContext} from "../context/GlobalContext";

const IndexPage = ({data}) => {
  const {positionData} = useContext(GlobalContext);

  // noinspection HtmlUnknownAnchorTarget
  return (
      <Layout isHomePage={true}>
        <SEO title="Academic Search Consulting"
             description="Pauly Group, a leading academic search consulting firm, focuses solely on senior administrative searches for community and technical colleges."/>
        <div className="homepage -container">
          <Grid container spacing={3} justify="center" alignItems="center" className="homepage -trusted-container">
            <CurrentOpenPositions positionData={positionData} />

            <Grid item sm={12} md={6}>
              <h3 className="homepage -trusted-header">TRUSTED & PROFESSIONAL</h3>
              <h2 className="homepage -trusted-subheader">Trusted by Community & Technical College <span
                  className="homepage -trusted-highlight">Trustees & Senior Leaders</span></h2>
              <h3 className="homepage -testimonial-header">Mercer County Community College, Presidental Search</h3>
              <p className="homepage -testimonial">"I had the pleasure of working with Pauly Group both as an employer
                and as a candidate. In all of my interactions with Pauly Group, I found
                Angela Provart (President) to be exceptional in providing extremely detail-oriented
                and caring services. She is also exemplary in providing follow-up services
                long after the service is complete."</p>
              <h4 className="homepage -testimonial-header">Dr. Jianping Wang, President<br/>Mercer County Community
                College</h4>
              <Link to="/success-stories/">
                <Button className="homepage -testimonials-button" aria-label="Read More Testimonials">READ MORE
                  TESTIMONIALS</Button>
              </Link>
            </Grid>
            <Grid item sm={12} md={6}>
              <div className="homepage -positions">
                <Image fluid={data.homepagePositionsImage.childImageSharp.fluid}/>
                <h2>WE ARE RECRUITING!</h2>
                <Link to="/positions/">
                  <Button className="homepage -positions-button" aria-label="View Positions">VIEW POSITIONS</Button>
                </Link>
              </div>
            </Grid>
          </Grid>
          <div className="homepage -cards">
            <div className="homepage -card-container">
              <h1 className="homepage -card-header">Pauly Group Finds the Right Candidates, The Right Way</h1>
              <Grid container spacing={3} justify="center">
                <Grid item sm={12} md={4}>
                  <Card>
                    <Image fluid={data.homepageSearchImage.childImageSharp.fluid}/>
                    <CardContent>
                      <Grid container spacing={3} justify="space-between" alignItems="center">
                        <Grid item xs={8}>
                          <h2 className="homepage -card-title">Search Preparation</h2>
                        </Grid>
                        <Grid item xs={4}>
                          <IconButton className="homepage -card-button-blue" aria-label="Link to Search Preparation">
                            <Link to="/what-we-do" aria-label="Link to Search Preparation">
                              <ArrowForwardIosOutlinedIcon className="homepage -cardIcon" alt="Arrow Button"/>
                            </Link>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Card>
                    <Image fluid={data.homepageApplicantRecruitmentImage.childImageSharp.fluid}/>
                    <CardContent>
                      <Grid container spacing={3} justify="space-between" alignItems="center">
                        <Grid item xs={8}>
                          <h2 className="homepage -card-title">Applicant Recruitment</h2>
                        </Grid>
                        <Grid item xs={4}>
                          <IconButton className="homepage -card-button-yellow"
                                      aria-label="Link to Applicant Recruitment">
                            <Link to="/what-we-do" aria-label="Link to Applicant Recruitment">
                              <ArrowForwardIosOutlinedIcon className="homepage -cardIcon" alt="Arrow Button"/>
                            </Link>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Card>
                    <Image fluid={data.homepageBoardCommitteeImage.childImageSharp.fluid}/>
                    <CardContent>
                      <Grid container spacing={3} justify="space-between" alignItems="center">
                        <Grid item xs={8}>
                          <h2 className="homepage -card-title">Board & Committee Assistance</h2>
                        </Grid>
                        <Grid item xs={4}>
                          <IconButton className="homepage -card-button-blue"
                                      aria-label="Link to Board & Committee Assistance">
                            <Link to="/what-we-do" aria-label="Link to Board & Committee Assistance">
                              <ArrowForwardIosOutlinedIcon className="homepage -cardIcon" alt="Arrow Button"/>
                            </Link>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={3} justify="center" alignItems="center">
                <Grid item sm={12} md={4}>
                  <Card>
                    <Image fluid={data.homepageApplicantContactImage.childImageSharp.fluid}/>
                    <CardContent>
                      <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={8}>
                          <h2 className="homepage -card-title">Applicant and Candidate Contact</h2>
                        </Grid>
                        <Grid item xs>
                          <IconButton className="homepage -card-button-yellow"
                                      aria-label="Link to Applicant and Candidate Contract">
                            <Link to="/what-we-do" aria-label="Link to Applicant and Candidate Contract">
                              <ArrowForwardIosOutlinedIcon className="homepage -cardIcon" alt="Arrow Button"/>
                            </Link>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Card>
                    <Image fluid={data.homepageReferencingImage.childImageSharp.fluid}/>
                    <CardContent>
                      <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={8}>
                          <h2 className="homepage -card-title">Referencing</h2>
                        </Grid>
                        <Grid item>
                          <IconButton className="homepage -card-button-blue" aria-label="Link to Referencing">
                            <Link to="/what-we-do" aria-label="Link to Referencing">
                              <ArrowForwardIosOutlinedIcon className="homepage -cardIcon" alt="Arrow Button"/>
                            </Link>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Card>
                    <Image fluid={data.homepageTechnologyImage.childImageSharp.fluid}/>
                    <CardContent>
                      <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={8}>
                          <h2 className="homepage -card-title">Technology</h2>
                        </Grid>
                        <Grid item xs>
                          <IconButton className="homepage -card-button-yellow" aria-label="Link to Technology">
                            <Link to="/what-we-do" aria-label="Link to Technology">
                              <ArrowForwardIosOutlinedIcon className="homepage -cardIcon" alt="Arrow Button"/>
                            </Link>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container spacing={3} justify="center" alignItems="center" className="homepage -standards-container">
            <Grid item sm={12} md={6}>
              <Image fluid={data.homepageExcellenceImage.childImageSharp.fluid}/>
            </Grid>
            <Grid item sm={12} md={6}>
              <h2 className="homepage -standards-header">Setting the Standard for Search Excellence Since 1990.</h2>
              <p className="homepage -standards-info">At Pauly Group, every one of our recruitment searches is
                customized
                to fit an institution and its unique needs and requirements. There are
                no cookie-cutter solutions or short-cuts. But there are some things in
                common. Professionalism. Attention to detail. Support. Constant
                communication. A commitment to diversity and gender equality.
                High ethical standards and confidentiality. And a standard of
                excellence that our clients find <span className="homepage -standards-bold">refreshing and indispensable.</span>
              </p>
              <p className="homepage -standards-info">We invite you to learn more about Pauly Group and how we deliver
                success.</p>

              <a href="#footer">
                <Button className="homepage -contact-button" aria-label="Contact Pauly Group">CONTACT US</Button>
              </a>
            </Grid>
          </Grid>
        </div>
      </Layout>
  )
}
export const query = graphql`
    query {
  homepagePositionsImage: file(relativePath: {eq: "Homepage-Positions-Image.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  homepageSearchImage: file(relativePath: {eq: "Homepage-Search-Preparation-Image.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 400, maxHeight: 300) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  homepageApplicantRecruitmentImage: file(relativePath: {eq: "Homepage-Applicant-Recruitment-Image.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 400, maxHeight: 300) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  homepageBoardCommitteeImage: file(relativePath: {eq: "Homepage-Board-Committee-Image.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 400, maxHeight: 300) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  homepageApplicantContactImage: file(relativePath: {eq: "Homepage-Applicant-Contact-Image.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 400, maxHeight: 300) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  homepageReferencingImage: file(relativePath: {eq: "Homepage-Referencing-Image.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 400, maxHeight: 300) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  homepageTechnologyImage: file(relativePath: {eq: "Homepage-Technology-Image.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 400, maxHeight: 300) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  homepageExcellenceImage: file(relativePath: {eq: "Homepage-Excellence-Image.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
  `

export default IndexPage;