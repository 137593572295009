import React, {useContext, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from "./divider";
import PositionSummary from "./positionSummary";
import PositionDialog from "./positionDialog";
import {GlobalContext} from "../context/GlobalContext";
import ApplyNowForm from "./applynowform";
import {Progress} from "./layout";


const CurrentOpenPositions = ({ positionData }) => {
    const [ selectedPosition, setSelectedPosition ] = useState(null);
    const [ applyNowPosition, setApplyNowPosition ] = useState(null);
    const { setMessageDialog, inProgress, setFullInProgress } = useContext(GlobalContext);

    if (!positionData || inProgress) return (Progress);

    if (positionData.positions.length === 0) return (<></>);

    return (<>
        <div className="position-summary-header">
            <h1>Current Open Positions</h1>
            <Divider/>
        </div>
        <Grid item sm={12} md={12} className="position-summary-outer">
            {Array.from(positionData.summary.entries()).map(
                ([schoolId, positionsSummary]) =>
                    <PositionSummary
                        key={'s-' + schoolId}
                        positions={positionsSummary}
                        school={positionData.schools.filter((school) => school.id === schoolId).at(0)}
                        setSelectPosition={setSelectedPosition}
                    />)
            }
        </Grid>

        {selectedPosition ?
            <PositionDialog
                position={selectedPosition}
                school={positionData.schools.filter((school) => school.id === selectedPosition.schoolId).at(0)}
                setApplyNowPosition={setApplyNowPosition}
                onClose={() => {
                  setApplyNowPosition(null);
                  setSelectedPosition(null);
                }}
            />
        : null}

        {applyNowPosition ? (
            <ApplyNowForm
                position={applyNowPosition}
                school={positionData.schools.filter((school) => school.id === selectedPosition.schoolId).at(0)}
                setError={(error) => setMessageDialog({severity:'error', message:error})}
                close={(success) => {
                    if (success) setMessageDialog({severity:'success', message:success});
                    setApplyNowPosition(null);
                }}
                onInProgress={() => setFullInProgress(true)}
                offInProgress={() => setFullInProgress(false)}
            />
        ) : null}

        <div className="position-summary-footer">
            <Divider/>
        </div>

    </>);
}

export default CurrentOpenPositions;
