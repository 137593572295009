import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import JobPosting from "./jobposting";
import './css/positionDialog.css';

const PositionDialog = ({ position, school, setApplyNowPosition, onClose }) => {
    if (position === null) return (<></>);

    return (
        <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth='lg'>
             <DialogTitle id="form-dialog-title">
                <Grid container justify="space-between">
                    <Grid item></Grid>
                    <Grid item><IconButton onClick={onClose}><CloseIcon /></IconButton></Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <JobPosting
                    key={position.id}
                    applyNowForm={ () => setApplyNowPosition(position) }
                    position={position}
                    school={school}
                />
            </DialogContent>

            <DialogActions style={{justifyContent:'center', backgroundColor:'#00324a'}}>
                <Button
                    onClick={onClose}
                    style={{color:'white'}}
                >
                    Close
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default PositionDialog;