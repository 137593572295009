import React, {useState} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import './css/positionSummary.css';

const PositionSummary = ({positions, school, setSelectPosition }) => {
    const [logoError, setLogoError] = useState(false);

    if (!Array.isArray(positions) || positions.length === 0) return (<></>);

    const schoolLogo = (school.hasLogo && !logoError)
        ? (
            <img
                alt={school.name}
                src={"/api/school/" + school.id + "/logo"}
                title={school.name}
                onError={() => setLogoError(true)}
            />
        ) : (
            <div className="-school-name-nologo">{school.name}</div>
        );

    return (
        <div className="position-summary" style={{margin: '2em'}}>
            <div className="-school-logo">{schoolLogo}</div>

            <hr style={{color: '#dcdcdc', width: '90%'}}/>

            <div className="-school-name">{school.name}</div>

            <List dense={true}>
                {positions.map((position) => {
                    return (
                        <div key={'p-' + position.id}>
                            <ListItem
                                button={true}
                                className="-school-position-summary"
                                onClick={ () => setSelectPosition(position) }
                            >
                                {position.Title}
                            </ListItem>
                        </div>);
                })}
            </List>
        </div>
    );
}

export default PositionSummary;
